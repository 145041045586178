.nav-tabs>li>a
{
  @media (max-width: 768px)
  {
    padding: 7px 8px!important;
  }
}

.tab-content
{
  background-color: #fff;
  border: 1px solid $border-color-main;
  padding: 15px;
  border-top: 0;
  position: relative;

  .page-header
  {
    margin-bottom: 20px;
  }

  &-info
  {
    border-left: 1px solid $border-color-main;
    display: none;
    top: 0;
    bottom: 0;
    right: 0;
    padding-left: 0;
    padding-right: 0;
    position: absolute;

    &.active
    {
      display: block;
    }

    &-header
    {
      background: $bg-color-tab-panel-info;
      color: $color-tab-panel-info;
      padding: 10px;

      button
      {
        color: $color-tab-panel-info;
      }
    }
  }

  .dropzone
  {
    position: absolute;
    left: 12px;
    right: 12px;
    bottom: 0;
    top: 0;
    z-index: 1;
    margin-bottom: 0;
    border: 4px dashed #9a9a9a;
    display: none;
    opacity: .95;
    background-color: #fff;

    &-placeholder
    {
      border: 4px dashed #9a9a9a;
      opacity: .95;
      background-color: #fff;
      padding: 20px;
      color: #8e8e8e;
      font-size: 26px;
      text-align: center;
      margin-bottom: 20px;
      cursor: pointer;
    }

    &.active,
    &.focus
    {
      display: block;
    }

    .dz-message
    {
      color: #8e8e8e;
      font-size: 40px;
      height: 100%;
    }

    &.dz-drag-hover
    {
      border: 4px dashed #9a9a9a;
    }
  }
}