.view-navigation {
  margin-bottom: 25px;
  padding-bottom: 15px;
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: center
}

.view-container {
  display: flex;
  width: calc(100% + 30px);
  margin: 0 -15px;

  &__col {
    padding: 0 15px;

    &--form {
      width: 50%;
    }

    &--file {
      width: 50%;

      iframe {
        border: 0;
        height: 100%;
        width: 100%;
      }
    }
  }
}

.view-form {
  .view-container__col--form {
    width: 100%;
  }

  .view-container__col--file {
    display: none;
  }
}

.view-file {
  .view-container__col--form {
    display: none;
  }

  .view-container__col--file {
    width: 100%;
    min-height: 750px;
  }
}

.view-split {
  .table-expense-container {
    margin-bottom: 15px;

    thead, tbody {
      font-size: 12px;
    }

    .form-control {
      font-size: 12px;
    }

    .table-expense {
      th.product-name {
        width: 250px;
      }

      th.w150 {
        width: 125px;
      }

      th.w135 {
        width: 100px;
      }
    }
  }

  .col-general {
    width: 100%;

    .form-label {
      @media (min-width: 768px) {
        width: 33.33333333%;
      }
    }

    .form-field-container {
      @media (min-width: 768px) {
        width: 66.66666667%;
      }
    }
  }

  .table-products-container {
    width: 100%;
    margin-left: 0;

    @media (min-width: 768px) {
      width: 66.66666667%;
      margin-left: 33.33333333%;
    }
  }

  .view-container__col--file {
    min-height: 750px;
  }
}