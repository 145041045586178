/* Duże okno modalne */
@media (min-width: 992px) {
  .modal-lg {
    width: 80%;
  }
}

@media (min-width: 768px) {
  .modal-dialog {
    margin: 30px auto 350px auto;
  }
}

.modal
{
  &-content
  {
    border-radius: 0;

    .form-mail
    {
      margin: -15px;

      .cke_chrome
      {
        border: 0;
      }

      input,
      select
      {
        border-width: 0 0 1px 0;
        padding: 10px 12px;
        height: auto;
        box-shadow: none;
        border-radius: 0;
        -webkit-appearance: none;
      }

      input[type="checkbox"] {
        -webkit-appearance: checkbox;
      }

      .checkbox-row {
        padding-left: 12px;
        margin-bottom: 10px;
        margin-top: 5px;
      }
    }

    .nav-tabs
    {
      margin: 0 -15px;
      padding-left: 15px;
    }
  }

  &-header
  {
    background: #404040;
    color: #fff;
    padding: 5px 10px 5px 15px;
    border-bottom: 0;
    margin-left: -1px;

    button.close
    {
      color: #fff;
      text-shadow: none;
      opacity: .5;
      padding: 0 5px;

      &:hover
      {
        opacity: .9;
        background: #7E7E7E;
      }
    }
  }

  &-title
  {
    font-size: 14px;
  }

  &-footer
  {
    background-color: #f5f5f5;
    border-top: 1px solid #BFBFBF;
    text-align: left;
    padding: 8px;
  }
}
