
.breadcrumb
{
  background: transparent;
  margin: 0;
}

div.breadcrumb
{
  margin: 0 -15px 12px;
  border-bottom: 1px solid #ddd;
  background-color: #fff;
  padding: 6px 15px;
}

div.breadcrumb-footer
{
  border-top: 1px solid #ddd;
}

div.breadcrumb .toggl-button
{
  margin-top: 6px;
}

ol.breadcrumb
{
  padding: 6px 0 0 0;
}

ol.breadcrumb.condense > li+li:before
{
  padding: 0;
  float: left;
}

ol.breadcrumb .document-scan
{
  display: inline-block;
  top: 1px;
  position: relative;
  color: #777;
}