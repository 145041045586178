@import "vars";
@import "global";
@import "loader";
@import "table";
@import "breadcrumb";
@import "btn";
@import "sidebar";
@import "menu";
@import "calendar";
@import "file_list";
@import "tabs";
@import "messages";
@import "modal";
@import "toggle";
@import "bank";
@import "drafts";

/* all */

.income
{color: #0a0}

.overdue,
.overdue a
{color: #d52131;}

.page-header
{margin: 0;}

.page-header a
{color: #333}


/* Bootstrap */

.table-hover>tbody>tr:hover
{background-color: #feffe3}

.panel
{border-radius: 0;}

.panel-heading
{
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.nav .open>a,
.nav .open>a:hover,
.nav .open>a:focus
{background-color: transparent}

.form-horizontal .control-label.text-left
{text-align: left}

.input-group-addon:first-child
{padding: 6px 9px;}

.input-group-addon:first-child .checkbox
{
    padding-top: 0;
    min-height: 0;
    margin-top: 2px;
}

.input-group-addon:first-child .checkbox label
{
    min-height: 12px;
    padding-left: 0;
    width: 12px;
}

.input-group-addon:first-child .checkbox label input
{
    margin-left: -6px;
}

.input-group-addon:last-child
{padding: 6px 9px;}

.list-unstyled ul {
    padding-left: 25px;
}

.alert
{
  .navbar-right
  {
    margin-top: -7px;
  }

  &-danger2
  {
    color: #fff;
    background-color: #d9534f;
    border-color: #d9534f;

    a
    {
      color: #fff;

      &:hover
      {
        color: #fff;
        text-decoration: underline;
      }
    }

    .nav>li>a:focus,
    .nav>li>a:hover
    {
      background: transparent;
    }
  }
}

/* Logo */
.navbar-brand
{
    width: 83px;
    padding: 10px 0;
}

.navbar-brand.no-padding
{
    padding: 4px 0 0 0;
    width: auto;
    margin-right: 5px;
}

.navbar-brand span.version
{
    font-size: 10px;
    display: block;
    position: relative;
    line-height: 1em;
    text-align: center;
}

@media (min-width: 768px)
{
    .navbar-brand
    {
        width: 70px;
        padding: 12px 0;
    }

    .navbar-brand .logo-img
    {
        width: 70px;
        display: block;
    }
}

.navbar-brand.login
{
    padding: 0;
    margin: -15px 0 0 -15px;
    opacity: 0.5;
}

.navbar-brand small
{
    white-space: nowrap;
    top: -20px;
    position: relative;
    font-size: 14px;
    font-weight: 600;
    color: #888;
}

/* Stopka */
.footer-eu
{
    margin-top: 40px;
    margin-bottom: 20px;
}

/* Nagłowek */

header.affix ~ .container-fluid
{margin-top: 100px}

header.affix
{
    position: fixed;
    z-index: 999;
    width: 100%;
    top: 0;
}

header.affix .navbar.sub-menu
{
    border-bottom: 2px solid #dddddd;
    min-height:unset;
    background: #f8f8f8;
}

header.affix .navbar.sub-menu .navbar-inner
{padding-top: 0}

header.affix .navbar.sub-menu .nav
{margin-bottom: 0}

header.affix .navbar.sub-menu .nav li.active a
{border-top: 1px solid transparent;}

.navbar
{
    border: 0;
    padding: 0;
    margin: 0;
    border-radius: 0;
}

.help-block
{
    margin-bottom: 0;
    font-size: 13px;
}

/* Zakładki */

.nav-tabs>li>a
{
    border: 1px solid #d8d8d8;
    border-radius: 0;
    color: #999;
    background-color: #f8f8f8;
}

.nav-tabs>li>a:hover
{
    border: 1px solid #d8d8d8;
    background-color: #fff;
    color: #666;
    text-decoration: none;
}

.nav-tabs li button.close
{
    position: relative;
    margin-right: -8px;
    padding-left: 10px;
    outline: none;
}

.nav>li>a
{padding: 8px 15px;}

a.text-muted
{text-decoration: none}

.thumbnail
{
    margin-bottom: 0;
}

/* Panel użytkownika zalogowanego */

.account-detail .dropdown-toggle span
{
    display: inline-block;
    line-height: 16px;
    margin-right: 5px;
}

.account-detail .dropdown-toggle .caret
{margin-top: -35px;}

.visible-xs .account-detail
{
    margin: 7px 0 0 15px;
}

.visible-xs .account-detail .dropdown-toggle
{
    color: #E2E2E2;
    text-decoration: none;
}

/* Tabele */
.amount
{
    text-align: right;
    white-space: nowrap;
}


.tab-settings
{
    line-height: 1em;
    margin-left: 4px;
}

.tab-settings .btn-link
{
    color: #888;
    padding: 0;
    line-height: 1em;
}

.serial-number
{
    color: #777;
    font-size: 13px;
    padding-top: 4px;
    padding-left: 2px;
}

/* Panel */

.panel .table>thead>tr>th
{
    border-bottom: 1px solid #ddd;
    font-weight: normal;
}

.panel .table>thead>tr>th:first-child
{
    padding-left: 15px;
}
/* Formularze */

.form-control
{border-radius: 0}

.form-horizontal.condensed .form-group
{margin-bottom: 5px}

.form-horizontal.condensed input.form-control,
.form-horizontal.condensed select.form-control
{height: 32px}

.form-group
{margin-bottom: 10px}

.form-control
{
    height: 30px;
    padding: 4px 12px;
}

@media (min-width: 768px)
{
    .form-horizontal .control-label
    {
        padding-top: 5px;
    }
}

.form-group.check label
{
    font-weight: normal;
    cursor: pointer;
}

.seperate-bottom
{
    border-bottom: 1px solid #eee;
    margin-bottom: 10px;
}

.input-group-addon
{background-color: #fff;}

.input-group-addon:first-child
{padding: 6px 10px;}

.input-group-addon.btn
{
    padding: 0;
    position: relative;
}

.input-group-addon.btn .btn
{
    border: 0;
    color: #666;
    width: 32px;
    padding: 4px 0;
}

.input-group-addon.btn.dropdown,
.input-group-addon.btn.remove
{border-left: 0;}

.input-group-addon.btn .btn:hover
{background-color: #eeeeee}

textarea.light-border
{
    border: 1px solid #EBEBEB;
    box-shadow: none;
}

/* Lista rozwijana */
.dropdown-menu.right
{
    left: auto;
    right: 0;
}

.dropdown-menu>li>a,
.dropdown-header
{
    color: gray;
    padding: 3px 40px 3px 10px;
}

.dropdown-menu>.active>a,
.dropdown-menu>.active>a:hover,
.dropdown-menu>.active>a:focus
{
    background: transparent;
    color: #000;
}

.dropdown-header
{
    font-weight: bold;
    cursor: pointer;
}

/* Autocompleter */

.autocomplete-suggestions { border: 1px solid #999; background: #FFF; cursor: default; overflow: auto; -webkit-box-shadow: 1px 4px 3px rgba(50, 50, 50, 0.64); -moz-box-shadow: 1px 4px 3px rgba(50, 50, 50, 0.64); box-shadow: 1px 4px 3px rgba(50, 50, 50, 0.64); }
.autocomplete-suggestion { padding: 2px 5px; white-space: nowrap; overflow: hidden; border-top: 1px solid #eee}
.autocomplete-suggestion .text-success { color: #218523}
.autocomplete-suggestion:first-child {border-top: 0}
.autocomplete-no-suggestion { padding: 2px 5px;}
.autocomplete-selected { background: #F0F0F0; }
.autocomplete-suggestions strong { font-weight: normal; color: #3399FF; }
.autocomplete-group { padding: 2px 5px; background: #D52131;}
.autocomplete-group strong {     display: block;  color: #E8E8E8;  font-weight: 600;  font-size: 12px; }

/* Dropdown */

.dropdown-menu.right
{
    right: 0;
    left: auto;
}

/* Pomoc */

.panel-help
{
    min-height: 62px;
    line-height: 1.5em;
}

.panel-help:hover
{text-decoration: none}

.panel-help .panel:hover
{
    background-color: #f5fdff;
    border-color: #8dd5ff;
}

.panel-help .icon,
.panel .icon
{
    color: #c0c0c0;
}

.panel-help .article,
.panel .article-header
{
    border-left: 1px solid #d0d4d9;
    padding: 15px 10px 10px;
    margin: -15px 0 -15px 30px;
}

.panel .article-header
{margin: -15px 0 0 30px;}

.panel-help .article h1,
.panel .article-header h1
{
    color: #222222;
    font-size: 14px;
    margin-bottom: 0;
}

.panel .article-header h1
{font-size: 20px;}

.panel-help .article .info,
.panel .article-header .info
{
    color: #808080;
    font-size: 10px;
}

.panel .article-header .info
{font-size: 12px}

.panel-help .article .description
{
    color: #222222;
    margin-bottom: 0;
}

.panel .article-body
{padding: 15px 50px 15px 50px;}

/* Informacje o zalogowanym użytkowniku */

#profile-info .info
{
    color: #c0c0c0;
    font-size: 12px;
    margin-bottom: 10px;
}

/* Ustawienia tabeli */
.list-group.connectedSortable
{min-height: 200px;}

.list-group.connectedSortable .list-group-item
{cursor: pointer}

.list-group.show
{border: 2px dashed gray;}

.list-group a.pull-left
{margin-right: 10px}

@media (min-width: 992px)
{
    .table-settings .description
    {min-height: 100px}
}

.active.sort .text-muted-more
{color: #777}

/* Wyszukiwarka */
.form-group-search
{position: relative}

.form-group-search .btn-search
{
    position: absolute;
    right: 0;
    top: 0;
    background: transparent;
    color: gray;
}

.form-group-search.form-group-sm
{
    margin-top: 10px;
    margin-bottom: 10px;
}

.form-group-search .form-control
{
    height: 26px;
    padding: 0 10px;
    font-size: 12px;
}

.form-group-search .form-control.datepicker
{width: 90px}

.form-group-search .input-group-addon,
.form-control-sm ~ .input-group-addon
{
    padding: 5px 9px;
    height: 26px;
    border-radius: 0;
}

.form-group-search label
{
    display: block;
    font-size: 11px;
    color: #777;
    font-weight: 600;
}

.form-group-search .btn
{
    height: 26px;
    line-height: 26px;
    padding: 0 12px;
    border-radius: 0;
}

.form-group-search.form-group-sm .btn-search
{
    font-size: 11px;
    padding: 5px 12px;
}

.form-group-search.form-group-sm .input-group-addon.btn
{
    border: 0;
    padding: 0;
    font-size: 0;
}

.form-group-search.form-group-sm .input-group-addon.btn .btn
{
    padding: 0;
    border: 1px solid #ccc;
}

/* Panel logów */
.panel-logs .info
{font-size: 12px;}

.panel-logs .header
{
    color: #444;
    margin-bottom: 10px;
    padding: 16px 6px 0;
    text-transform: uppercase;
}

.panel-logs .header:first-child
{padding-top: 0;}

/* Tooltip dla pola TextCopy */

.tooltiptext
{
    visibility: hidden;
    width: 140px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 3;
    bottom: 150%;
    left: 50%;
    margin-left: -75px;
    opacity: 0;
    transition: opacity 0.3s;
}

.tooltiptext::after
{
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}

.btn:hover .tooltiptext
{
    visibility: visible;
    opacity: 1;
}

/* Upload plików */
.dropzone
{
    padding: 100px 50px 100px 50px;
    margin-bottom: 50px;
    border: 5px dashed #d6d6d6;
    text-align: center;
}

.form-horizontal .form-group.dropzone
{
    margin-right: 0;
    margin-left: 0;
}

.fileinput-button
{
    position: relative;
    overflow: hidden;
    margin-left: 1px;
}

.fileinput-button input {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    opacity: 0;
    font-size: 24px;
    direction: ltr;
    cursor: pointer;
}

.file-box .gui-file
{
    width: 100%;
    height: 100%;
    cursor: pointer;
    padding: 8px 10px;
    position: absolute;
    opacity: 0;
    z-index: 11;
    bottom: 0;
    right: 0;
}

.file-box .field-icon
{
    left: 0;
    top: 0;
    width: 42px;
    height: 42px;
    color: inherit;
    line-height: 42px;
    position: absolute;
    text-align: center;
    transition: all 0.5s ease-out 0s;
    pointer-events: none;
    z-index: 99;
    font-size: 20px;
}

.file-box .gui-input
{
    padding: 10px;
    padding-left: 36px;
    background: white none repeat scroll 0 0;
    position: relative;
    border: 1px solid #CCC;
    border-radius: 4px;
    display: inline-block;
    outline: medium none;
    height: 42px;
    width: 100%;
    font-size: 16px;
}

.has-error .gui-input
{
    border: 1px solid #A94442
}

.file-box .file-btn
{
    position: absolute;
    top: 4px;
    right: 4px;
    float: none;
    height: 34px;
    line-height: 34px;
    padding: 0 16px;
    z-index: 10;
}

.file-box
{
    position: relative;
}

.file-size-label
{
    font-size: 12px;
}

/* Zlecenia */
.order-path
{
    padding: 10px;
    position: relative;
    top: -11px;
    margin-bottom: -11px;
    border: 1px solid #D3D3D3;
    margin-left: 10px;
    float: left;
}

.order-path .glyphicon
{
    color: lightgray;
    margin: 0 5px;
}

.products div.order-task
{
    color: gray;
    font-size: 10px;
    text-align: center;
}

.products .remove-tr .glyphicon
{
    font-size: 24px;
}

.task-checkbox-link .glyphicon
{
    font-size: 24px;
}

.task-checkbox-link .glyphicon-check
{
    color: #318031
}

.task-checkbox-link .glyphicon-unchecked
{
    color: #777
}
/* End zleceń */

/* Harmonogram */

.row-calendar
{
    font-size: 13px;
}

.row-calendar .time
{
    float: left;
    width: 90px;
}

.row-calendar .description
{
    margin-left: 90px;
    font-weight: bold;
}

.row-calendar .description.no-margin
{margin-left: 0;}

.holiday
{
    background: #ffbfbf;
}

/* End Harmonogram */

/*Inbox Menu*/
.inbox .inbox-nav
{
    margin: 0;
    padding: 0;
    list-style: none;
}

.inbox .inbox-nav li
{position: relative;}

.inbox .inbox-nav li a
{
    display: block;
    border-left: none;
    text-align: left;
    padding: 8px 14px;
}

.inbox .inbox-nav .list-group-item
{border: 0;}

.inbox .inbox-nav .list-group-item:first-child
{border-radius: 0;}

.inbox .inbox-nav li.active a
{
    color: #d9534f;
    border-left: none;
    text-decoration: none;
    background-color: transparent;
    font-weight: bold;
}

.inbox a.list-group-item.active>.badge,
.inbox .nav-pills>.active>a>.badge
{
    color: #fff;
    background-color: #d9534f;
}

.inbox .inbox-nav li:hover a
{
    background-color: #f5f5f5;
    text-decoration: none;
}

.inbox .inbox-nav li.compose-btn a
{
    color: #fff;
    text-align: center;
    font-weight: bold;
}

.inbox .inbox-nav li.compose-btn i,
.inbox .inbox-nav li.compose-btn:hover i
{
    top: 1px;
    color: #fff;
    font-size: 15px;
    position: relative;
    background: none !important;
}

.inbox .inbox-nav li.compose-btn a:hover
{background-color: #1d943b;}

#btn-compose
{
    padding: 8px 12px;
    text-shadow: 0 1px rgba(0,0,0,0.1);
    font-size: 13px;
    font-weight: bold;
}

/*Inbox Content*/
.inbox .inbox-header
{overflow: hidden;}

.inbox .inbox-header .refresh
{margin: 10px;}

.inbox .inbox-header h1,
.inbox .inbox-header h2
{
    margin: 10px 0;
    color: #666;
}

.inbox-view-header h1 a,
.inbox-view-header h2 a
{
    top: -2px;
    color: #fff;
    cursor: pointer;
    font-size: 13px;
    padding: 2px 7px;
    line-height: 16px;
    position: relative;
    background: #b0bcc4;
    display: inline-block;
}

.inbox-view-header h1 a:hover,
.inbox-view-header h2 a:hover
{
    background: #aab5bc;
    text-decoration: none;
}

.inbox-view-info {
    color: #666;
    padding: 5px 0;
    margin-bottom: 10px;
    border-top: solid 1px #eee;
    border-bottom: solid 1px #eee;
}

.inbox-view-info strong {
    color: #666;
    margin: 0 10px 0 5px;
}

.inbox-view-info .inbox-info-btn
{text-align: right;}

.inbox-view-info .inbox-info-btn .btn-group
{
    margin-top: -2px;
    margin-bottom: 2px;
}

.inbox-view-info .inbox-info-btn ul
{text-align: left;}

.inbox-view-info button,
.inbox-view-info a.btn
{
    top: 2px;
    font-size: 13px;
    margin-left: 4px;
    padding: 3px 10px;
    position: relative;
}

.inbox .pagination-control
{text-align: right;}

.inbox .pagination-control .pagination-info
{
    display: inline-block;
    padding-right: 10px;
    font-size: 13px;
    line-height: 14px;
}

.inbox tr
{
    color: #777;
    font-size: 13px;
}

.inbox tr label
{
    display: inline-block;
    margin-bottom: 0;
}

.inbox tr.unread td
{font-weight: bold;}

.inbox td i.icon-paper-clip
{
    top: 2px;
    color: #d8e0e5;
    font-size: 17px;
    position: relative;
}

.inbox tr i.icon-star,
.inbox tr i.icon-trash
{cursor: pointer;}

.inbox tr span.glyphicon-star
{color: #eceef0;}

.inbox tr span.glyphicon-star:hover
{color: #fd7b12;}

.inbox tr span.inbox-started
{color: #fd7b12;}

.inbox .table th,
.inbox .table td
{border: none;}

.inbox .table th
{
    border-bottom: solid 5px #fff;
    font-weight: normal;
}

.inbox th.text-right
{text-align: right;}

.inbox th label.inbox-select-all
{
    color: #828f97;
    font-size: 13px;
    padding: 1px 4px 0;
}

.inbox ul.inbox-nav
{margin-bottom: 0;}

.inbox ul.inbox-nav li
{padding: 0;}

.inbox ul.inbox-nav li span
{
    font-size: 12px;
    margin-right: 10px;
}

.inbox ul.inbox-nav i
{
    color: #fff;
    padding: 1px 0;
    font-size: 15px;
    cursor: pointer;
    background: #d0dde4 !important;
}

.inbox ul.inbox-nav i:hover
{background: #169ef4 !important;}

.inbox td.text-right
{
    width: 100px;
    text-align: right;
}

.inbox td.inbox-small-cells
{width: 10px;}

.inbox .table-hover tbody tr:hover > td,
.inbox .table-hover tbody tr:hover > th,
.inbox .table-striped tbody > tr:nth-child(odd) > td,
.inbox .table-striped tbody > tr:nth-child(odd) > th
{cursor: pointer;}

/*Inbox Compose*/
.inbox-compose
{
    margin-top: 1px;
    border: solid 1px #eee;
}

.inbox-compose-btn
{
    padding: 8px 4px;
    background: #f8f8f8;
}

.inbox-compose-attachment
{padding: 8px 8px;}

.inbox-compose-attachment .btn
{padding: 4px 10px;}

.inbox-compose-btn button
{
    font-size: 14px;
    margin-left: 4px;
    padding: 4px 10px;
}

.inbox-compose .inbox-form-group
{
    margin-bottom: 0;
    position: relative;
    border-bottom: solid 1px #eee;
}

.inbox-compose .controls
{margin-left: 85px;}

.inbox-compose .inbox-form-group > label
{
    width: 80px;
    float: left;
    color: #979797;
    text-align: right;
}

.inbox-compose .controls > input
{
    border: none;
    box-shadow: none;
}

.inbox-compose .controls-to
{padding-right: 55px;}

.inbox-compose .controls-cc
{padding-right: 20px;}

.inbox-compose .controls-bcc
{padding-right: 20px;}

.inbox-compose .inbox-form-group a.close
{
    top: 7px;
    right: 10px;
    position: absolute;
}

.inbox-compose .mail-to .inbox-cc-bcc
{
    display: inline-block;
    top: 7px;
    right: 10px;
    color: #979797;
    font-size: 14px;
    cursor: pointer;
    position: absolute;
}

.inbox-compose .mail-to .inbox-cc-bcc a
{color: #888888;}

.inbox-compose .mail-to .inbox-bcc
{margin-left: 5px;}

.inbox-compose .mail-to inbox-cc:hover,
.inbox-compose .mail-to inbox-bcc:hover
{color: #777;}

.inbox-compose .wysihtml5
{
    padding: 0 !important;
    margin: 0 !important;
    border: 0 !important;
}

.inbox-compose .wysihtml5-sandbox
{
    padding: 0 !important;
    margin: 0 !important;
    display: block !important;
    border: 0 !important;
    margin-top: 5px;
    width: 100% !important;
    border-left: none;
    border-right: none;
    border-color: #eee;
}

.inbox-compose .wysihtml5-toolbar
{
    border: 0;
    border-bottom: 1px solid #eee;
}

.inbox-compose .wysihtml5-toolbar > li
{
    height: 30px;
    margin-right: 0;
    margin-bottom: 0;
}

.inbox-compose .wysihtml5-toolbar > li > a,
.inbox-compose .wysihtml5-toolbar > li > div > a
{
    background: #fff;
    box-shadow: none;
    color: #000;
}

.inbox-compose .wysihtml5-toolbar .dropdown.open .dropdown-toggle,
ul.wysihtml5-toolbar a.btn.wysihtml5-command-active
{background: #eee !important;}

@media (max-width: 480px)
{
    .inbox-compose .inbox-form-group > label
    {
        margin-top: 7px;
    }
}

ul.wysihtml5-toolbar
{
    margin: 0;
    padding: 0;
    display: block;
}

ul.wysihtml5-toolbar:after
{
    clear: both;
    display: table;
    content: "";
}

ul.wysihtml5-toolbar > li
{
    float: left;
    display: list-item;
    list-style: none;
    margin: 0 5px 10px 0;
}

ul.wysihtml5-toolbar a[data-wysihtml5-command=bold]
{
    font-weight: bold;
}

ul.wysihtml5-toolbar a[data-wysihtml5-command=italic]
{
    font-style: italic;
}

ul.wysihtml5-toolbar a[data-wysihtml5-command=underline]
{
    text-decoration: underline;
}

ul.wysihtml5-toolbar a.btn.wysihtml5-command-active
{
    background-image: none;
    -webkit-box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15),0 1px 2px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15),0 1px 2px rgba(0, 0, 0, 0.05);
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15),0 1px 2px rgba(0, 0, 0, 0.05);
    background-color: #E6E6E6;
    background-color: #D9D9D9;
    outline: 0;
}

ul.wysihtml5-commands-disabled .dropdown-menu
{
    display: none !important;
}

ul.wysihtml5-toolbar div.wysihtml5-colors
{
    display:block;
    width: 50px;
    height: 20px;
    margin-top: 2px;
    margin-left: 5px;
    position: absolute;
    pointer-events: none;
}

ul.wysihtml5-toolbar a.wysihtml5-colors-title
{padding-left: 70px;}

ul.wysihtml5-toolbar div[data-wysihtml5-command-value="black"]
{background: black !important;}

ul.wysihtml5-toolbar div[data-wysihtml5-command-value="silver"]
{background: silver !important;}

ul.wysihtml5-toolbar div[data-wysihtml5-command-value="gray"]
{background: gray !important;}

ul.wysihtml5-toolbar div[data-wysihtml5-command-value="maroon"]
{background: maroon !important;}

ul.wysihtml5-toolbar div[data-wysihtml5-command-value="red"]
{background: red !important;}

ul.wysihtml5-toolbar div[data-wysihtml5-command-value="purple"]
{background: purple !important;}

ul.wysihtml5-toolbar div[data-wysihtml5-command-value="green"]
{background: green !important;}

ul.wysihtml5-toolbar div[data-wysihtml5-command-value="olive"]
{background: olive !important;}

ul.wysihtml5-toolbar div[data-wysihtml5-command-value="navy"]
{background: navy !important;}

ul.wysihtml5-toolbar div[data-wysihtml5-command-value="blue"]
{background: blue !important;}

ul.wysihtml5-toolbar div[data-wysihtml5-command-value="orange"]
{background: orange !important;}

blockquote {
    margin-top: 20px;
    margin-left: 10px;
    margin-bottom: 20px;
    border-left: 1px solid #666 !important;
    padding-left: 5px;
    display: block;
    opacity: 0.7;
    filter: alpha(opacity=70);
}

.inbox-attached .icon
{
    color: #CCCCCC;
    margin-left: 5px;
}

.inbox-attached .icon:hover
{color: #ADADAD;}

/* Notes */
.note
{
    padding: 10px 10px 50px 10px;
    margin-bottom: 10px;
    background: #faf6ea;
    border-top: solid 2px #faf6ea;
    position: relative;
}

.note h3
{
    margin-bottom: 0;
}

.note .info
{
    color: #a9a9a9;
    font-size: 12px;
    margin-bottom: 10px;
}

.note .actions
{
    position: absolute;
    bottom: 0;
    height: 30px;
    width: 100%;
    margin-left: -10px;
}

.note .actions .btn
{
    background-color: transparent;
    border-color: transparent;
    box-shadow: none;
    padding: 4px 5px;
    opacity: 0.6;
}

.note .actions .btn:hover
{opacity: 1;}

/* Faktury */
.invoice-info,
.invoice-info .row
{margin-bottom: 10px;}

/* Uprawnienia */
.list-heading
{
    background-color: #f5f5f5;
    border: 1px solid #ddd;
    color: #428bca;
    margin-bottom: 10px;
    padding: 5px 10px;
}

label.required
{white-space: nowrap;}

label.required:before
{
    content: "*";
    color: #d52131;
    display: inline-block;
    margin-right: 2px;
}

/** DataTables JS */

.dataTables_filter,
.dataTables_length
{display: none}

.dataTables_paginate
{
    height: 34px;
    margin-bottom: 20px;
}

.dataTables_paginate span
{
    display: inline-block;
    position: relative;
    float: left;
}

.dataTables_paginate span span
{
    background-color: #fff;
    border: 1px solid #ddd;
    padding: 6px 12px;
    margin-left: -1px;
    line-height: 1.42857143;
}

.dataTables_paginate a
{
    position: relative;
    float: left;
    padding: 6px 12px;
    margin-left: -1px;
    line-height: 1.42857143;
    color: #337ab7;
    text-decoration: none;
    background-color: #fff;
    border: 1px solid #ddd;
}

.dataTables_paginate > a:first-child
{
    margin-left: 0;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.dataTables_paginate > a:last-child
{
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.dataTables_paginate a:hover
{
    color: #23527c;
    cursor: pointer;
    background-color: #eee;
    border-color: #ddd;
}

.dataTables_paginate a.current
{
    z-index: 2;
    color: #fff;
    cursor: default;
    background-color: #337ab7;
    border-color: #337ab7;
}

.itemClick
{cursor: pointer}

/*.dataTables_wrapper*/
/*{overflow-x: scroll}*/

@media (min-width: 992px)
{
    .dataTables_wrapper
    {overflow: visible}
}

table.dataTable thead .sorting_asc:after {
    content: "\e155";
}

table.dataTable thead .sorting:after {
    opacity: 0.2;
    content: "\e150";
    color: #D4D4D4;
}

table.dataTable thead .sorting_desc:after {
    content: "\e156";
}

table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:after
{
    position: absolute;
    bottom: 8px;
    right: 8px;
    display: block;
    font-family: 'Glyphicons Halflings';
    opacity: 0.5;
}

table.dataTable thead .sorting,
table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc,
table.dataTable thead .sorting_asc_disabled,
table.dataTable thead .sorting_desc_disabled
{
    cursor: pointer;
    position: relative;
}

table.dataTable tbody tr.expanded > td,
table.dataTable tbody td.expanded
{background: #ffc}

table.dataTable tbody td.expanded
{padding: 0}

table.dataTable tbody td.expanded .table
{margin-bottom: 0}

/* Email */
.checkbox .item-mail-address
{margin-top: -2px;}

.item-mail-address
{
    display: inline-block;
    border: 1px solid #ebebeb;
    background-color: #f9f9f9;
    color: #666;
    font-size: 12px;
    padding: 4px 5px 2px;
}

.item-mail-address:hover,
.item-mail-address:hover .count,
.item-mail-address .count
{
    color: #303641;
    background-color: #dbdbdd;
    border-color: #d0d0d3;
}

.item-mail-address:hover,
.item-mail-address:focus
{text-decoration: none;}

.item-mail-address .count
{
    display: inline-block;
    border-left: 1px solid #ebebeb;
    font-weight: bold;
    padding: 4px 7px 2px;
    margin: -4px -5px -2px 0;
    position: relative;
}

.nav-vnet.nav>li>a
{
    color: #666;
    border: 1px solid #ebebeb;
}

.nav-vnet.nav>li.active>a,
.nav-vnet.nav>li>a:hover
{
    color: #303641;
    background-color: #dbdbdd;
    border-color: #d0d0d3;
}

/* Systm loader */

.modal .loader,
.tab-content .loader
{
    margin: 6px;
    position: static;
    display: block;
    top: 0;
    left: 0;
}

.modal .loader
{
    width: 550px;
}

.modal .loader > div,
.tab-content .loader > div
{
    margin: 100px auto;
    border: 0;
    display: block;
}

.modal .row.gus
{
    border-bottom: 1px solid #ccc;
    border-top: 1px solid #ccc;
    padding: 8px 0;
    margin: -16px -15px 10px;
    background: #eee;
}

.modal .row.gus .btn
{padding: 4px 10px}

.loader
{
    position: fixed;
    top: 50%;
    left: 50%;
    display: none;
    z-index: 3;
}

.loader > div
{
    background: #fff;
    border: 1px solid lightgrey;
    display: inline-block;
    width: 225px;
    height: 64px;
    margin: -32px 0 0 -85px;
    padding: 10px;
}

.loader .text
{
    font-size: 24px;
    float: left;
    font-weight: bold;
    line-height: 0.6em;
    margin: 10px 0 0 10px;
}

.loader small
{
    font-size: 12px;
    font-weight: normal;
}

.loader .red
{color: #b1272d}

.spinner {
    width: 50px;
    height: 40px;
    text-align: center;
    font-size: 10px;
    float: left;
}

.spinner > div {
    background-color: #333;
    height: 100%;
    width: 6px;
    display: inline-block;

    -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
    animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.spinner .rect2 {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s;
}

.spinner .rect3 {
    -webkit-animation-delay: -1.0s;
    animation-delay: -1.0s;
}

.spinner .rect4 {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
}

.spinner .rect5 {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
}

@-webkit-keyframes sk-stretchdelay {
    0%, 40%, 100% { -webkit-transform: scaleY(0.4) }
    20% { -webkit-transform: scaleY(1.0) }
}

@keyframes sk-stretchdelay {
    0%, 40%, 100% {
        transform: scaleY(0.4);
        -webkit-transform: scaleY(0.4);
    }  20% {
           transform: scaleY(1.0);
           -webkit-transform: scaleY(1.0);
       }
}

.rows-condensed .form-group
{
    margin-bottom: 5px;
}

/* Listy rozwijane */
.input-select .btn
{
    border-radius: 0;
    padding: 3px 12px;
}

.input-select .dropdown-menu
{
    margin-top: 0;
    border-radius: 0;
}

.input-select .form-group-search.form-group-sm
{
    margin-top: 0;
    margin-bottom: 0;
}

/* Datepicker */
.input-group.date
{width: 135px}

.datepicker-icon
{cursor: pointer}

/* Statystyki wyszukiwania */
ul.stats
{
    list-style: none;
    margin: 15px 0 0 0;
    padding: 0;
    font-size: 11px;
    float: left;

    li
    {
        float: left;
        margin-left: 10px;

        &:first-child
        {
            margin-left: 0;
        }

        strong.text-muted
        {
            color: #9E9E9E;
        }
    }

    &.tab-settings
    {
        font-size: 12px;
    }
}

/* Popover */
.popover
{color: #000}

.popover hr
{margin: 5px 0}

/* Statusy zleceń */

.select2-container--default .select2-selection--multiple
{
    border: 1px solid #ccc;
    border-radius: 3px;
}

/* Strony błędów */
.error-page
{
    text-align: center;
    color: #000;
    font-family: 'Comfortaa', cursive;
}

.modal .error-page
{padding: 20px 0}

.error-page h2
{
    font-size: 38px;
    font-weight: bold;
    margin-bottom: 0;
}

.error-page h1
{
    font-size: 142px;
    font-weight: bold;
    margin-bottom: 0;
}

@media (min-width: 768px)
{
    .error-page h2
    {font-size: 90px}

    .modal .error-page h2
    {font-size: 60px}

    .error-page h1
    {font-size: 320px}

    .modal .error-page h1
    {font-size: 220px}
}

.error-page .links
{margin-top: 40px}

.tab-content .error-page .links,
.modal .error-page .links
{display: none}

.error-page .links a
{}

.error-page .links .module a
{
    display: block;
    color: #888;
    border: 1px solid #DDDDDD;
    background-color: white;
    padding: 10px;
    margin-bottom: 10px;
}

.error-page .links .module a:hover
{
    color: #222;
    border: 1px solid #222;
    text-decoration: none;
}

/* Zakładki w oknie modalnym */

.modal .tab-content
{
    border: 0;
    padding: 15px 0 0 0;
}

/* Szablony */

.layouts .thumbnail .preview
{
    width: 100%;
    display: block;
    background-color: #eee;
    text-align: center;
    font-size: 32px;
    font-weight: bold;
    text-shadow: 1px 1px 1px rgba(150, 150, 150, 1);
    height: 195px;
    overflow: hidden;
}

.layouts .thumbnail .preview a
{
    color: #808080;
    position: absolute;
    padding: 75px 0;
    z-index: 1;
    left: 0;
    right: 0;
}

.layouts .thumbnail .preview a:hover
{text-decoration: none}

.layouts .thumbnail .preview img
{opacity: 0.2}

/* Lista wyboru */
.btn-group.width-max .caret
{
    float: right;
    margin-top: 8px;
}

.btn-group.width-max .btn
{text-align: left}

/* Nawigacja menu główne ikony */

.navbar ul li.toolbar-icon > a
{
    margin: 5px 0 0;
    font-size: 18px;
    padding-bottom: 0;
}

.toolbar-icon > a span.glyphicon
{font-size: 24px}

.dropdown-menu.dropdown-alternate {
    background: #fff !important;
    color: #607d8b !important;
    padding: 0;
    z-index: 1300;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
    border-top-left-radius: 2px !important;
    border-top-right-radius: 2px !important;
    border: 1px solid #e0e0e0;
    width: 298px;
}

.open .dropdown-menu {
    position: absolute;
}

.open .dropdown-menu li.divider:first-child
{
    display: none;
}

.dropdown-menu.dropdown-alternate .dd-header {
    padding: 8px 12px;
    font-size: 11px;
    border-bottom: 1px solid #e0e0e0;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.04);
}

.dropdown-menu.dropdown-alternate .dd-footer {
    font-size: 11px;
    padding: 8px 12px;
    text-align: right;
    border-top: 1px solid #e0e0e0;
    box-shadow: 0 -1px 1px 0 rgba(0, 0, 0, 0.04);
}

.dropdown-menu.dropdown-alternate li {
    border-bottom: 1px solid #eeeeee;
    min-height: 60px;
}

.dropdown-menu.dropdown-alternate a:hover
{text-decoration: none}

.dropdown-menu.dropdown-alternate li:hover
{
    background: #f7f7f7;
}

.notifications-item li
{
    position: relative;
}

.notification-info
{
    position: relative;
    padding: 10px 40px 10px 15px!important;
}

.notification-disable
{
    position: absolute;
    right: 0;
    top: 16px;
}

.notification-disable:hover
{
    color: #D52131!important;
}

.notification-icon
{
    position: absolute !important;
    left: 16px;
    top: 16px;
    width: 32px;
    height: 32px;
    border-width: 0;
    border-style: solid;
    text-align: center;
    border-radius: 50%;
    line-height: 32px;
}

.notification-icon.green
{
    background: #f1f8e9;
    border-color: #f1f8e9;
    color: #8bc34a;
}

.dropdown-menu.dropdown-alternate li:hover .notification-icon.green
{
    background: #8bc34a !important;
    border-color: #8bc34a;
    color: #fff;
}

.notification-icon.red
{
    background: #fde0dc;
    border-color: #fde0dc;
    color: #e51c23;
}

.dropdown-menu.dropdown-alternate li:hover .notification-icon.red
{
    background: #e51c23 !important;
    border-color: #e51c23;
    color: #fff;
}

.notification-icon.blue
{
    background: #e1f5fe;
    border-color: #e1f5fe;
    color: #03a9f4;
}

.dropdown-menu.dropdown-alternate li:hover .notification-icon.blue
{
    background: #03a9f4 !important;
    border-color: #03a9f4;
    color: #fff;
}

.notification-content
{
    margin-left: 50px
}

.badge {
    padding: 1px 5px;
    font-size: 11px;
    font-family: 'Open Sans', 'Droid Sans', Tahoma, Arial, sans-serif;
    line-height: 14px;
    color: rgba(255, 255, 255, 0.9) !important;
    position: absolute;
    top: 2px;
    right: 6px;
}

.badge {
    display: inline-block;
    min-width: 10px;
    padding: 4px 7px;
    font-size: 11px;
    font-weight: 600;
    color: #ffffff;
    line-height: 1em;
    vertical-align: baseline;
    white-space: nowrap;
    text-align: center;
    background-color: #D52131;
    border-radius: 50%;

}

.nav-tabs .badge
{
    position: relative;
    top: auto;
    right: auto;
    background-color: #4CAF50;
    margin-left: 5px;
}

textarea.auto-resize {
    box-sizing: border-box;
    max-height: 500px; /* optional, but recommended */
    min-height: 31px;
    overflow-x: hidden; /* for Firefox (issue #5) */
}

.form-mini
{
    white-space: nowrap;
    font-size: 0;
    padding: 0 10px;
}

.form-mini .form-control
{float: none}

/* Filtry */

.dropdown-menu li:hover
{background-color: #f5f5f5}

.dropdown-menu .filter-remove,
.dropdown-menu .filter-save
{
    position: absolute;
    z-index: 1;
    padding: 3px 5px;
    width: 24px;
    height: 26px;
}

.dropdown-menu .filter-remove
{right: 4px}

.dropdown-menu .filter-save
{right: 28px}

.dropdown-menu .filter-remove:hover
{background: transparent}

.dropdown-menu .filter-remove .glyphicon,
.dropdown-menu .filter-save .glyphicon
{color: #ccc}

.dropdown-menu .filter-remove:hover .glyphicon
{color: #800000}

.dropdown-menu .filter-save:hover .glyphicon
{color: green}

.flash{

    -moz-animation: flash 1s ease-out;
    -moz-animation-iteration-count: 1;

    -webkit-animation: flash 1s ease-out;
    -webkit-animation-iteration-count: 1;

    -ms-animation: flash 1s ease-out;
    -ms-animation-iteration-count: 1;

}

@-webkit-keyframes flash {
    0% { background-color:none;}
    50% { background-color:#DFF0D8}
    100% {background-color:none;}
}

@-moz-keyframes flash {
    0% { background-color:none;}
    50% { background-color:#DFF0D8}
    100% {background-color:none;}
}

@-ms-keyframes flash {
    0% { background-color:none;}
    50% { background-color:#DFF0D8}
    100% {background-color:none;}
}

.ico-star
{
    cursor: pointer;
    display: inline-block;
    width: 19px;
    height: 19px;
    background: url("../images/ico-star-off.png") no-repeat
}

.ico-star.active,
.ico-star:hover
{background: url("../images/ico-star-on.png") no-repeat}

.btn-start.btn
{
    position: relative;
    padding-left: 24px;
}

.btn-start::before
{
    background: url("../images/ico-star-off.png") no-repeat;
    content: '';
    display: block;
    width: 19px;
    height: 19px;
    position: absolute;
    left: 2px;
    top: 3px;
}

.btn-start.center::before
{
    background-position: center center;
    left: 2px;
    right: 2px;
    width: auto;
}

.btn-start.active::before
{background: url("../images/ico-star-on.png") no-repeat}

.btn-column
{
    vertical-align: top;
    width: 35px;
}

.btn-column .btn
{
    padding: 5px 9px;
}

.btn-star span
{
    color: #808080;
}

.btn-star.active span
{
    color: #4CAF50;
}

.currency-price
{
    font-size: 13px;
    color: #757575;
    display: block;
    text-align: right;
}
.expanded
{background: #FFFDE7}

.btn-link.btn-help-popover
{padding: 0}

.tags
{
    float: left;
    margin-top: 6px;
    margin-left: 10px;
    font-size: 12px;

}

.tags .tag
{
    float: left;
    margin-right: 10px;
    font-size: 0;
}

.tags .tag a,
.tags .tag span
{
    font-size: 11px;
    display: inline-block;
    color: rgb(102, 102, 102);
    background-color: rgb(221, 221, 221);
    padding: 2px 7px;
}

.tags .tag a:hover,
.tags .tag span:hover
{
    cursor: pointer;
    color: rgb(221, 221, 221);
    background-color: rgb(102, 102, 102);
    text-decoration: none;
}

.tag-dropdown
{
    width: 400px;
    max-height: 350px;
    font-size: 12px;
    overflow: hidden;
}

.tag-dropdown hr
{
    margin-top: 7px;
    margin-bottom: 8px;
}

.tag-search .tag-search-label
{
    padding: 8px 8px 0;
}

.tag-search .tag-search-input
{
    overflow: hidden;
    border: 1px solid;
    border-color: #888 #ccc #ccc #ccc;
    padding: 1px 20px 1px 1px;
    margin: 6px 8px;
    position: relative;
}

.tag-search .tag-search-input input
{
    border: 0;
    background: #fff;
    color: #222;
    font-size: 100%;
    margin: 0;
    width: 100%;
    outline: 0!important;
}

.tag-search .tag-search-input span
{
    position: absolute;
    right: 4px;
    top: 4px;
}


.tag-list
{
    height: 212px;
    overflow: auto;
    overflow-x: hidden;
}

.tag-list-item
{
    padding: 3px 30px 3px 30px;
    background: url("../images/tristate-unchecked.png") no-repeat left center;
}

.tag-list-item.checked
{
    background: url("../images/tristate-checked.png") no-repeat left center;
}

.tag-list-item:hover
{
    background-color: #eee;
    text-decoration: none;
    cursor: pointer;
}

.tag-actions a
{
    color: #000;
    display: block;
    padding: 3px 30px 3px 30px;
}

.tag-actions a:hover
{
    background-color: #eee;
    text-decoration: none;
}

.tags {
    &--map {
        margin: -3px 0 5px 0;
        float: none;
        display: flex;
        flex-wrap: wrap;

        .tag {
            margin-top: 3px;
            margin-right: 3px;

            span {
                &:hover {
                    cursor: default;
                    background-color: #ddd;
                    color: #666666;
                }
            }
        }
    }
}

.counter-container
{
    position: fixed;
    bottom: 15px;
    right: 15px;
    background: #fff;
    border: 1px solid #cdcdcd;
    padding: 10px;
    box-shadow: 0 2px 6px rgba(0,0,0,0.1);
    font-size: 12px;
    text-align: right;
}

.counter-value
{
    text-align: right;
    font-weight: bold;
    color: #777;
}

.counter-label
{padding-right: 10px}

/* Plugin clipboardTexts */

.cke_dialog_body .clipboardTexts
{
    max-height: 250px;
}

.cke_dialog_body .clipboardTexts label
{
    border: 1px solid gray;
    padding: 10px 20px;
    display: block;
    cursor: pointer;
    margin-bottom: 10px;
}

.cke_dialog_body .clipboardTexts label input[type="radio"]
{
    display: none;
}

.cke_dialog_body .clipboardTexts label:hover,
.cke_dialog_body .clipboardTexts label.active
{
    background: #ffc;
    border: 1px solid #ffd76e;
}

.cke_textarea_inline {
    padding: 5px 5px 0 5px;
}

.changed {
    background-color: gold;
}

.privacy-hidable.collapse {
    display: none;
}

.privacy-hidable.collapse.in {
    display: table-cell;
}

.sticky-header thead:first-child tr th {
    position: sticky;
    top: 45px;
    z-index: 3;
}