/* Menu nawigacji */
#main-menu
{margin-top: 6px;}

#main-menu li a
{text-align: center}

#main-menu > li > span.glyphicon
{font-size: 26px}

#main-menu li span.text
{
  display: block;
  font-size: 11px;
}

#main-menu li ul
{
  padding: 10px 0;
  min-width: 190px;
}

#main-menu li li
{padding: 0 10px 10px}

#main-menu .btn-group
{
  display: table;
  width: 100%;
}

#main-menu .btn-group a
{
  display: table-cell;
  float: none;
}

#main-menu .btn-group a.btn-extra
{
  padding: 0 4px;
  width: 30px;
}

@media (min-width: 992px)
{
  #main-menu > li > a
  {
    text-align: left;
    cursor: default;
  }

  #main-menu > li > span.glyphicon
  {
    font-size: 14px;
    display: none;
  }

  #main-menu li span.text
  {
    display: inline-block;
    font-size: 14px;
  }
}

@media (min-width: 1200px)
{
  #main-menu > li > span.glyphicon
  {display: inline-block}
}


.navbar.main-menu,
nav.navbar
{
  background: #ffffff;
  box-shadow: 0 2px 6px rgba(0,0,0,0.1);
  position: relative;
  width: 100%;
  z-index: 4;

  .navbar-brand
  {
    padding: 0;

    img
    {
      width: auto;
      max-height: 38px;
      max-width: 70px;
      margin: 0 auto;
    }
  }
}

nav
{
  .main-menu
  {
    background-color: darkred;
  }

  button
  {
    border: 0;
  }
}

nav .main-menu button.navbar-toggle:hover,
nav .main-menu button.navbar-toggle:focus
{background-color: transparent}

nav .main-menu button.navbar-toggle .icon-bar
{background-color: #E2E2E2;}

nav .main-menu-item
{
  position: absolute;
  left: 0;
  right: 0;
  background: #f5f5f5;
  z-index: 4;
  border-bottom: 1px solid;
}

nav .main-menu-item ul > li > a
{
  color: #888;
  padding: 10px 15px;
  display: block;
}

.navbar.main-menu ul > li > a
{
  color: #888;
  padding: 4px 15px 0;
  display: block;
  border-bottom: 4px;
  border-bottom-style: solid;
  border-bottom-color: transparent;
}

@media (min-width: 992px)
{
  .navbar.main-menu ul > li > a
  {padding: 10px 15px}
}

.navbar.main-menu .nav > li.active > a
{
  border-color: #d52131;
  color: #000;
}

.navbar.main-menu .nav > li > ul > li > a
{
  margin: 5px 10px;
  border: 1px solid #dddddd;
  padding: 5px 10px;
  border-radius: 4px;
  text-align: center;
  font-weight: 400;
}

.navbar.main-menu ul.nav > li > a:hover,
.navbar.main-menu ul.nav > li > a:focus
{
  background-color: transparent;
  border-color: #d52131;
}

.navbar.main-menu ul.nav .dropdown-menu li:hover
{
  background-color: transparent;
}

.navbar.main-menu .nav > li > a:hover
{color: #000;}

.navbar-nav.sub-menu
{
  margin: 0;
  background-color: #f8f8f8;
  border-top: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
  position: relative;
  width: 100%;
  z-index: 3;

  .dropdown-menu
  {
    a
    {
      display: block;
      padding: 5px 0;
      color: #555;
    }

    ul
    {
      margin: 7.5px 0;

      a
      {
        padding: 5px 15px;
      }
    }
  }

  & > li > a
  {
    padding: 10px 15px;
  }

  .nav
  {
    margin-bottom: -1px;
  }
}


.navbar.sub-menu .nav a
{
  color: #888;
  padding: 10px 15px;
  border: 1px solid transparent;
}

.navbar.sub-menu .nav a:hover
{
  color: #000;
  background-color: transparent;
}

.navbar.sub-menu .navbar-inner
{
  padding-top: 10px;
}

.navbar.sub-menu .nav li.active a
{
  background-color: #fff;
  color: #000;
  border: 1px solid #dddddd;
  border-bottom-color: #fff;
}

.navbar-nav.sub-menu .caret
{
  margin-top: 10px;
}

@media (min-width: 768px)
{
  .account-info
  {
    font-size: 22px;
    line-height: normal;
    margin-right: 8px;
  }
}

@media (min-width: 992px)
{
  .account-info
  {
    display: inline-block;
    line-height: 16px;
    margin-right: 10px;
    font-size: 14px;
  }
}
