.btn
{
  border-radius: 3px;
  padding: 4px 12px;
  box-shadow: inset 0 -2px 0 0 rgba(0,0,0,.05);
}

.btn-sm
{
  padding: 2px 8px;
}

.input-group-btn
{vertical-align: top}

.input-group-btn>.btn
{padding: 5px 12px;}

.btn-link, .btn-link:active, .btn-link[disabled], fieldset[disabled] .btn-link
{
  box-shadow: none;
}

.btn-default:hover, .btn-default:focus, .btn-default:active, .btn-default.active, .open>.dropdown-toggle.btn-default
{
  background: #f2f2f2;
}

.btn.btn-icon,
.btn.btn-icon:focus,
.btn.btn-icon:active,
.btn.btn-icon:link
{
  outline: none;
  color: #31708f;
}

.btn-group-input
{
  width: 100%;
  display: table;
}

.btn-group-input.btn-group > a
{
  display: table-cell;
  float: none;
  text-align: left;
}

.btn-group-input a.btn-extra
{
  padding: 0 4px;
  width: 35px;
  text-align: center;
}

.btn-toolbar
{
  margin-bottom: 10px;
}
