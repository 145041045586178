.bank
{
  .document
  {
    padding: 2px 5px;
    font-size: 11px;
    font-weight: 600;
    line-height: 1em;
    vertical-align: baseline;
    white-space: nowrap;
    text-align: center;
    text-transform: uppercase;
    border-radius: 3px;
    border: 1px solid #000;
    display: inline-block;

    a.action
    {
      display: inline-block;
      border-left: 1px solid #000;
      border-bottom: 1px solid #000;
      padding: 4px;
      margin: -2px 0;

      &:hover
      {
        background-color: #000!important;
      }

      &.accept
      {
        background-color: #00aa00;
        color: #fff;
        margin-left: 5px;
      }

      &.search
      {
        background-color: #2196f3;
        color: #fff;
        margin-left: 5px;
        margin-right: -5px;
      }

      &.remove
      {
        background-color: #ff0000;
        color: #fff;
        margin-right: -5px;
      }
    }

    &.suggestion
    {
      font-size: 14px;
      background-color: #eeeeee;

      .suggestion-amount
      {
        display: block;
        font-size: 11px;
        text-transform: lowercase;
        text-align: left;
      }
    }

    &.connect
    {
      background-color: #4caf50;
      color: #fff;
    }

    &.category
    {
      background-color: #ff9800;
      color: #fff;
    }
  }

  &-transaction-title
  {
    font-size: 15px;
  }

  &-transaction-contractor
  {
    font-size: 13px;
    color: #b6b6b6;
  }
}