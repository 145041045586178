.toggle
{
  .time-day
  {
    &-header
    {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: rgb(255, 255, 255);
      height: 50px;
      line-height: 23px;
      font-weight: 600;

      &-label
      {
        display: flex;
        align-items: center;
        margin-right: auto;
        color: #000;
        font-size: 14px;

      }

      &-time
      {
        color: #000;
        font-size: 14px;
        line-height: 23px;
        font-weight: 600;
        white-space: pre-wrap;
        margin: 0 37.5px;
      }
    }

    &-item
    {
      position: relative;
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      height: 50px;
      padding-left: 15px;
      padding-right: 10px;
      border-bottom: 1px solid #e8e8e8;
      margin: 0 -15px;

      &:hover
      {
        background-color: #feffe3;
      }

      &-description
      {
        max-width: 60%;
      }

      &-project
      {
        position: relative;
        height: 100%;
        display: flex;
        align-items: center;
        overflow: hidden;
        flex: 0 1 auto;
        color: #4BC800;
        padding: 0 8px;

        a
        {
          color: #4BC800;
        }
      }

      &-tags
      {
        position: relative;
        min-width: 80px;
        display: flex;
        justify-content: center;
        text-align: right;
        margin-left: auto;
        cursor: pointer;
        flex: 0 2 auto;
        overflow: hidden;
      }

      &-time
      {
        min-width: 250px;
        height: 100%;
        flex: 0 0 auto;
        padding: 0 10px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-flow: row-reverse nowrap;

        &-total
        {
          height: 20px;
          line-height: 20px;
          padding-left: 10px;
          text-align: right;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          flex: 0 0 87px;
        }

        &-range
        {
          color: rgb(123, 123, 123);
          height: 20px;
          line-height: 20px;
          padding-left: 10px;
          text-align: right;
          display: block;
          padding-right: 0;
          flex: 1 0 auto;
        }
      }

      &-actions
      {
        .options
        {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 30px;
          height: 30px;
          cursor: pointer;
          border-radius: 8px;
        }
      }
    }
  }
}