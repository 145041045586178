.file
{
  &-block
  {
    background-color: #fff;
    background-image: none;
    color: #555;
    float: left;
    margin: 0 20px 20px 0;
    width: 212px;
    box-shadow: 0 1px 3px rgba(0,0,0,.12), 0 1px 2px rgba(0,0,0,.24);
    position: relative;

    &:hover
    {
      box-shadow: 0 1px 3px 1px rgba(60,64,67,.2), 0 2px 8px 4px rgba(60,64,67,.1);
      cursor: pointer;
    }

    a
    {
      text-decoration: none;
      outline: 0;
    }

    &-icon
    {
      height: 150px;
      text-align: center;
      border-bottom: 1px solid #bbb;
      overflow: hidden;

      a
      {
        left: 0;
        right: 0;
        top: 0;
        bottom: 58px;
        padding-top: 78px;
        z-index: 2;
      }

      &-scan
      {
        position: absolute;
        right: 10px;
        top: 10px;
      }

      .bg-image
      {
        background-size: cover;
        display: block;
        height: 100%;
        background-position-y: center;

        .lightbox-indicator
        {
          position: absolute;
          display: none;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;

          &>span
          {
            position: absolute;
            top: 50%;
            left: 50%;
            height: 40px;
            width: 40px;
            margin: -20px 0 0 -20px;
            background-color: #222;
            background-image: none;
            border-radius: 50%;

            i
            {
              float: left;
              height: 40px;
              width: 40px;
              line-height: 40px;
              text-align: center;
              color: #fff;
            }
          }
        }
      }
    }

    &-actions
    {
      padding: 8px 4px;
      text-align: center;

      .btn
      {
        margin: 0;
        padding: 0;
        width: 35px;
        height: 30px;
        min-width: 35px;
        line-height: 32px;
        color: #000000;
        box-shadow: none;
        background-color: transparent;

        &:hover
        {
          background-color: #e5e5e5;
        }
      }
    }

    &-info
    {
      height: 78px;
      padding: 10px;
      background-image: none;
      z-index: 1;
      position: relative;

      &-title
      {
        margin-top: 0;
        margin-bottom: 0;
        color: inherit;
        font-size: 16px;
        font-weight: 600;
        line-height: 1.1;
        overflow: hidden;
        text-overflow:ellipsis;
        max-height: 36px;
        overflow-wrap: break-word;
      }

      &-subtitle
      {
        font-size: 14px;
        line-height: 1.6;
        color: #0000008a;
      }
    }
  }
}
