input[type="text"].loading
{
  content: " ";
  background: url("../images/loading-input.gif") no-repeat 98% center;
  padding-right: 30px;
}

a.loading,
span.loading
{
  background: url("../images/loading-input.gif") no-repeat center center !important;
  min-width: 16px;
  min-height: 16px;
  display: block;
  border: transparent;
  box-shadow: none;
}

a.loading .glyphicon
{
  visibility: hidden;
}

span.loading
{
  margin-right: 10px;
  float: left;
  margin-top: 3px;
}


/* Loader - circle */

.page-loader-wrapper p {
  font-size: 13px;
  margin-top: 10px;
  font-weight: bold;
  color: #444;
}

.preloader-wrapper {
  -webkit-animation: container-rotate 1568ms linear infinite;
  animation: container-rotate 1568ms linear infinite;
}

.preloader-wrapper {
  width: 48px;
  height: 48px;
}

.preloader-wrapper {
  display: inline-block;
  position: relative;
  width: 48px;
  height: 48px;
}

.spinner-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  border-color: #d52131;
}

.spinner-layer
{
  opacity: 1;
  -webkit-animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
  animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}

.circle-clipper
{
  display: inline-block;
  position: relative;
  width: 50%;
  height: 100%;
  overflow: hidden;
  border-color: inherit;
}

.left {
  float: left !important;
}

.right {
  float: right !important;
}

.circle-clipper.left .circle
{
  -webkit-animation: left-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
  animation: left-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}

.circle-clipper.left .circle
{
  left: 0;
  border-right-color: transparent !important;
  -webkit-transform: rotate(129deg);
  transform: rotate(129deg);
}

.circle-clipper.right .circle
{
  -webkit-animation: right-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
  animation: right-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}

.circle-clipper.right .circle {
  left: -100%;
  border-left-color: transparent !important;
  -webkit-transform: rotate(-129deg);
  transform: rotate(-129deg);
}

.circle-clipper .circle {
  width: 200%;
  height: 100%;
  border-width: 3px;
  border-style: solid;
  border-color: inherit;
  border-bottom-color: transparent !important;
  border-radius: 50%;
  -webkit-animation: none;
  animation: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
}

.circle {
  border-radius: 50%;
}

.gap-patch {
  position: absolute;
  top: 0;
  left: 45%;
  width: 10%;
  height: 100%;
  overflow: hidden;
  border-color: inherit;
}

@-webkit-keyframes left-spin {
  from {
    -webkit-transform: rotate(130deg)
  }

  50% {
    -webkit-transform: rotate(-5deg)
  }

  to {
    -webkit-transform: rotate(130deg)
  }
}

@keyframes left-spin {
  from {
    -webkit-transform: rotate(130deg);
    transform: rotate(130deg)
  }

  50% {
    -webkit-transform: rotate(-5deg);
    transform: rotate(-5deg)
  }

  to {
    -webkit-transform: rotate(130deg);
    transform: rotate(130deg)
  }
}

@-webkit-keyframes right-spin {
  from {
    -webkit-transform: rotate(-130deg)
  }

  50% {
    -webkit-transform: rotate(5deg)
  }

  to {
    -webkit-transform: rotate(-130deg)
  }
}

@keyframes right-spin {
  from {
    -webkit-transform: rotate(-130deg);
    transform: rotate(-130deg)
  }

  50% {
    -webkit-transform: rotate(5deg);
    transform: rotate(5deg)
  }

  to {
    -webkit-transform: rotate(-130deg);
    transform: rotate(-130deg)
  }
}

@-webkit-keyframes fill-unfill-rotate {
  12.5% {
    -webkit-transform: rotate(135deg)
  }

  25% {
    -webkit-transform: rotate(270deg)
  }

  37.5% {
    -webkit-transform: rotate(405deg)
  }

  50% {
    -webkit-transform: rotate(540deg)
  }

  62.5% {
    -webkit-transform: rotate(675deg)
  }

  75% {
    -webkit-transform: rotate(810deg)
  }

  87.5% {
    -webkit-transform: rotate(945deg)
  }

  to {
    -webkit-transform: rotate(1080deg)
  }
}

@keyframes fill-unfill-rotate {
  12.5% {
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg)
  }

  25% {
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg)
  }

  37.5% {
    -webkit-transform: rotate(405deg);
    transform: rotate(405deg)
  }

  50% {
    -webkit-transform: rotate(540deg);
    transform: rotate(540deg)
  }

  62.5% {
    -webkit-transform: rotate(675deg);
    transform: rotate(675deg)
  }

  75% {
    -webkit-transform: rotate(810deg);
    transform: rotate(810deg)
  }

  87.5% {
    -webkit-transform: rotate(945deg);
    transform: rotate(945deg)
  }

  to {
    -webkit-transform: rotate(1080deg);
    transform: rotate(1080deg)
  }
}

@-webkit-keyframes container-rotate {
  to {
    -webkit-transform: rotate(360deg)
  }
}

@keyframes container-rotate {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg)
  }
}
