.messages
{
  padding-left: 0;
  list-style: none;

  &-day-separator
  {
    text-align: center;

    &-text
    {
      background: #fff;
      display: inline-block;
      z-index: 201;
      padding: .25rem .75rem;
      border-radius: 24px;
      position: relative;
    }

    hr
    {
      left: 0;
      right: 0;
      border-top: 1px solid #ddd;
      border-bottom: 0;
      margin: 0 0 -1px;
      top: -12px;
      position: relative;
    }
  }

  &-item
  {
    padding: 8px 15px;
    position: relative;

    &:hover
    {
      background-color: #f8f8f8;

      .avatar-time
      {
        visibility: visible;
      }

      .messages-item-action
      {
        display: block;
      }
    }

    .media-body,
    .media-left,
    .media-right
    {
      display: table-cell;
      vertical-align: top;
    }

    .media-left,
    .media>.pull-left
    {
      padding-right: 10px;
    }

    .avatar
    {
      width: 40px;
      height: 40px;
      overflow: hidden;
      display: inline-block;
      vertical-align: middle;

      &.time
      {
        height: auto;
      }

      .glyphicon
      {
        color: #9e9e9e;
        font-size: 36px;
      }

      &-time
      {
        color: #616061;
        font-size: 12px;
        visibility: hidden;
      }
    }

    &-person
    {
      font-weight: 700;
      font-size: 15px;
      margin-bottom: 5px;

      small
      {
        font-weight: 400;
        font-size: 12px;
        color: #757575;
      }
    }

    &-text
    {
      color: #1d1c1d;
      font-weight: 400;
      margin-bottom: 0;
      line-height: 1.4;
      font-size: 14px;
      margin-top: 8px;
    }

    &-files
    {
      margin-top: 8px;

      &-item
      {
        display: inline-block;

        &-meta
        {
          color: #6f6f6f;
          font-size: 12px;
          margin-bottom: 5px;
        }

        &-preview
        {
          display: inline-block;

          a
          {
            display: block;

            img
            {
              max-width: 200px;
              max-height: 400px;
            }
          }
        }

        &-link
        {
          padding: 12px;
          border: 1px solid #dddddd;
          background-color: #fff;
          display: inline-block;

          &-icon
          {
            width: 36px;
            height: 40px;
            overflow: hidden;
            margin-right: 12px;
            display: inline-block;
            float: left;
          }

          &-description
          {
            font-size: 13px;
            font-weight: 400;
            display: inline-block;
            color: #6f6f6f;

            strong
            {
              font-size: 16px;
              font-weight: 700;
              color: #1d1c1d;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
              display: block;
            }
          }
        }

        .lightbox-link
        {
          cursor: zoom-in;
        }
      }
    }

    &-action
    {
      position: absolute;
      right: 20px;
      top: 5px;
      display: none;

      button
      {
        box-shadow: none;
        padding: 4px 8px 2px;
      }
    }
  }
}