ul.sidebar.list-group
{
  list-style: none;
  border: 1px solid #ddd;
}

ul.sidebar .list-group-item:first-child,
ul.sidebar .list-group-item.disabled:first-child
{
  border-radius: 0;
  border-top: 0;
}

ul.sidebar .list-group-item.disabled:hover
{
  color: #222;
}

ul.sidebar.list-group .list-group-item
{
  border: 0;
  margin-bottom: 0;
}

ul.sidebar.list-group .disabled
{
  color: #222;
  border-bottom: 1px solid #ddd;
  border-top: 1px solid #ddd;
}


ul.sidebar.list-group .list-group-item.header
{
  background-color: #eee;
  color: #222;
  border-bottom: 1px solid #ddd;
}