.calendar
{
  &-container
  {

  }

  &-external-orders
  {
    .fc-event
    {
      background-color: #fff;
      border: 1px solid #000;
      color: #000000;
      margin-bottom: 5px;
      padding: 5px 10px;

      button
      {
        padding: 0;
      }
    }
  }
}