body
{
  background: #f8f8f8;
  font-family: 'Open Sans', sans-serif;
}

.font-12 {font-size: 12px}

select:focus,
textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
.form-control:focus
{
  background: #fffbea;
  border: 1px solid #e7b557;
  border-color: #e7b557;
  border-bottom-color: #ffd88d;
  border-right-color: #ffd88d;
  box-shadow: none;
  -webkit-box-shadow: none;
}

input[type="text"].archived
{text-decoration: line-through}

input[type="text"].disable,
select.disable
{
  background: #f5f5f5;
  cursor: not-allowed;
}

.mark,
mark
{
  background: #fff3b3;
  padding: 0;
}

::-webkit-input-placeholder { font-style: italic; font-size: 13px; line-height: 22px;}
::-moz-placeholder { font-style: italic;  font-size: 13px; line-height: 22px;}
:-ms-input-placeholder { font-style: italic;  font-size: 13px; line-height: 22px;}
input:-moz-placeholder { font-style: italic;  font-size: 13px; line-height: 22px;}

.margin-label,
.margin-label.stats
{margin-top: 30px}

.margin-sm {margin: 5px}

.margin-top    {margin-top: 15px}
.margin-top-xl {margin-top: 25px}

.margin-top-bottom {margin: 15px 0}

.margin-top-xs                    {margin-top: 5px}
.margin-top-sm, .small-margin-top {margin-top: 10px}

.margin-right-xs {margin-right: 5px}

.margin-left-xs {margin-left: 5px}

.no-margin-bottom {margin-bottom: 0}
.margin-bottom-sm {margin-bottom: 5px;}
.margin-bottom    {margin-bottom: 15px;}
.margin-bottom-xl {margin-bottom: 25px;}

.margin-bottom-none,
.margin-bottom-none.border-all
{margin-bottom: 0}

.table>thead>tr>th.padding-3px,
.padding-3px
{padding: 3px}

.pl-30 {padding-left: 30px!important;}

.label-padding-top {padding-top: 7px;}

.label-block
{display: block}

.border-top    {border-top: 1px solid #d0d4d9;}
.border-bottom {border-bottom: 1px solid #d0d4d9;}
.border-all    {border: 1px solid #d0d4d9;}

.border-all,
.padding-all
{
  padding: 10px 5px;
  margin-bottom: 10px;
}

.padding-none
{
    padding: 0!important;
}

.border-all .list-inline {margin-bottom: 0}

.border-all.company-info p {margin-bottom: 2px}

.width
{
  &-max {width: 100%}
  &-80px {width: 80px}
  &-70px {width: 70px}
  &-30px {width: 30px}
  &-40px {width: 40px}
  &-20px {width: 20px}
}

.mx-auto
{
    margin-left: auto;
    margin-right: auto;
}

.width-max-1024 {max-width: 1024px}

.not-displayed
{
  display: none;
}

.font-bold
{
  font-weight: bold;
}

.font-big
{
  font-size: 20px;
}

.no-border-top,
.table>tbody>tr>td.no-border-top
{
  border-top: 0;
}

.text-inline
{
  white-space: nowrap;
  overflow: hidden;
}

.text-black,
.text-black:hover
{
  color: $color-black;
}

.text-black-underline,
.text-black-underline:hover
{
  color: #333;
  text-decoration: underline;
}

.text-muted-more
{
  color: $color-muted;
}

.text-warning
{
  color: $color-orange;
}

.text-overflow
{
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}

.relative
{
  position: relative;
}

.divider
{
  height: 1px;
  margin: 15px 0;
  overflow: hidden;
  background-color: #e5e5e5;
}

.dib
{
  display: inline-block;
}

label.no-bold
{
  font-weight: normal;
}

h1, .h1,
h2, .h2,
h3, .h3
{
  margin-top: 0;
  margin-bottom: 10px;
}

#flash-messenger
{
  position: fixed;
  top: 0;
  z-index: 99999;
  width: 400px;
  left: 50%;
  margin-left: -200px;
}

a.not-change
{
  color: #333;
}

a.not-change:hover
{
  text-decoration: none;
}

.keyword-field-list
{
  .checkbox
  {
    display: block;

    label
    {
      min-height: 27px;
      line-height: 27px;
      padding: 0 10px;

      &:hover
      {
        background: $bg-hover;
      }
    }
  }
}

.container-fluid
{
  @media (max-width: 768px)
  {
    padding-right: 4px;
    padding-left: 4px;
  }
}

.mailto
{
  &-link
  {
    position: relative;
    padding: 8px 0;

    &:hover .mailto-message,
    &:focus .mailto-message,
    &:focus-within .mailto-message
    {
      display: block;
    }
  }

  &-message
  {
    top: 1px;
    left: 50%;
    margin-bottom: -5px;
    transform: translate(-50%, -100%);
    position: absolute;
    display: none;
    width: auto;
    white-space: nowrap;
    font-size: 12px;
    background-color: black;
    color: white;
    padding: 2px 6px;
    border-radius: 2px;

    &:after,
    &:before
    {
      content: '';
    }

    &:before
    {
      top: 100%;
      left: 50%;
      border: solid transparent;
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: rgba(0, 0, 0, 0);
      border-top-color: #000000;
      border-width: 4px;
      margin-left: -4px;
    }
  }
}
