
.table
{
  background: #ffffff;
  width: auto;
  min-width: 100%;
}

.table>thead>tr>td,
.table>tbody>tr>td,
.table>tfoot>tr>td
{
  padding: 2px 4px;
  word-wrap: break-word;
}

.table>thead>tr.bg-yellow>td,
.table>tbody>tr.bg-yellow>td,
.table>tfoot>tr.bg-yellow>td
{
  background: #fcf8e3;
}

.table>thead>tr>th,
.table>tbody>tr>th,
.table>tfoot>tr>th
{padding: 8px 4px}

.table>thead>tr>th.sorting,
.table>thead>tr>th.sorting_desc,
.table>thead>tr>th.sorting_asc
{
  padding: 8px 30px 8px 4px;
  outline: 0;
}

.table tfoot.total
{border-top: 2px solid #808080}

.table.font-small
{font-size: 13px}

.table th,
.table .th
{
  background-color: #f5f5f5;
  font-weight: bold;
}

.table th.remove,
.table th.w40
{width: 40px}

.table th.w49
{width: 49px}

.table th.lp,
.table th.check,
.table td.check,
.table th.w50,
.table td.w50
{width: 50px;}

.table th.pkwiu
{width: 60px;}

.table th.unit,
.table th.quantity,
.table th.w65,
.table td.w65
{width: 65px;}

.table th.vat,
.table th.discount
{width: 75px;}

.table th.w80,
.table td.w80,
table td.w80
{width: 80px}

.table th.w90,
.table td.w90,
li.w90
{width: 90px}

.w94
{width: 94px}

.table th.w95,
.table td.w95,
li.w95
{width: 95px}

.table th.w99
{width: 99px}

.table th.date,
.table th.w100,
.table td.w100,
.table th.gross-total,
.table th.net-total,
.table th.net,
.table th.gross,
.table th.action,
li.w100
{width: 100px;}

.table th.w110,
li.w110
{width: 110px}

.table th.w115,
li.w115
{width: 115px}

.table th.w120,
li.w120
{width: 120px}

.table th.w125
{width: 125px}

.table th.w135,
.table td.w135
{width: 135px;}

.table th.w140 {width: 140px}

.table th.w150,
.table th.datetime
{width: 150px}

.table th.w160 {width: 160px}
.table th.w170 {width: 170px}
.table th.w180 {width: 180px}
.table th.w190 {width: 190px}

.table th.w200,
.table td.w200,
.table th.user
{width: 200px;}

.table th.w250,
.table td.w250
{width: 250px}

.table th.w300,
.table td.w300
{width: 300px}

.table th.w450 {width: 450px}

.table td.nowrap,
.table th.nowrap
{white-space: nowrap;}

.table th.min-w100
{min-width: 100px}

.table th.min-w110
{min-width: 110px}

.table th.min-w200
{min-width: 200px}

.table th.name
{min-width: 300px;}

.table th.w33p,
.table td.w33p
{width: 33%}

.table-nav
{
  float: right;
  margin-bottom: 4px;
}

.table-nav .btn.dropdown-toggle
{
  font-size: 13px;
}

table.user-list small
{
  line-height: 1em;
  display: block;
  color: #808080;
  padding-bottom: 4px;
}

table.products input
{
  padding: 6px;
}

table.products select
{
  padding: 6px 4px;
}

th.col-date {width: 100px}
td.col-date {white-space: nowrap;}

th.col-datetime {width: 150px}
td.col-datetime {}

th.col-id {width: 50px}
td.col-id {text-align: center}

th.col-true-false {}
td.col-true-false {text-align: center}

th.col-amount {width: 100px}
td.col-amount {text-align: right;white-space: nowrap;}

th.col-currency {}
td.col-currency {}

th.col-vat {}
td.col-vat {}

th.col-quantity {}
td.col-quantity {}

.table .required
{
  color: #d52131;
}

.table .optional
{
  display: none;
}

.table .sub-value,
.table .sub-value a
{
  font-size: 11px;
  color: #888;
}

.table .bold-sub .sub-value,
.table .bold-sub .sub-value a
{
  font-size: 11px;
  color: #000;
}

.table-bordered>thead>tr>th,
.table-bordered>thead>tr>td
{
  border-bottom-width: 1px;
}

.table .expired td
{
  background-color: #B0413E;
  color: #FFF;
}

.table .expired td a
{
  color: #FFF!important;
}

.table.table-noborder td
{border-top: 0}

.table.table-noborder .border-all
{margin-bottom: 0}

.table .company-stamp
{
  position: absolute;
  top: 2px;
  bottom: 16px;
  left: 0;
  right: 5px;
  text-align: center;
}

.table .company-stamp div.small
{
  position: absolute;
  bottom: 5px;
  left: 0;
  right: 0;
}
